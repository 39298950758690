<script>
import debounce from "lodash/debounce";
import Swal from "sweetalert2";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import CText from "@/components/Form/CText.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import PlusSign from "@/components/Icons/PlusSign.vue";
import BtnSecondary from "@/components/Global/BtnSecondary.vue";

export default {
    components: { BtnSecondary, PlusSign, BtnPrimary, CText, ModalWindow },
    props: {
        resourceId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            processing: false,
            show: false,
            form: {
                code: "",
                class_name: "",
                student_first_name: "",
                student_last_name: "",
                student_id: "",
                student_email: "",

                fillEmail: "",
                canReceiveEmail: "",
                useMicrosoftOrGoogle: "",
            },
        };
    },

    methods: {
        updateFillEmail: debounce(function () {
            this.form.fillEmail = this.form.student_email ? "1" : "0";
        }, 1000),
        addCode(event) {
            const formData = this.form; // new FormData();

            let uri = "/resources";
            if (this.resourceId) {
                uri += `/${this.resourceId}`;
            }
            uri += "/codes";

            this.processing = true;
            axios
                .post(uri, formData)
                .then((response) => {
                    this.show = false;
                    Swal.fire({
                        title: "Success",
                        text: "Code added successfully!",
                        icon: "info",
                    }).then(() => {
                        // Clear form data
                        this.form.code = "";
                        this.form.class_name = "";
                        this.form.student_first_name = "";
                        this.form.student_last_name = "";
                        this.form.student_id = "";
                        this.form.student_email = "";

                        this.form.fillEmail = "";
                        this.form.canReceiveEmail = "";
                        this.form.useMicrosoftOrGoogle = "";

                        this.$emit("created", response.data);
                    });
                })
                .catch(({ response }) => {
                    this.show = false;

                    if (response?.status === 422) {
                        let errors = "";
                        if (response?.data.errors) {
                            errors = Object.values(response.data.errors).join("<br>");
                        }

                        Swal.fire({
                            title: "Encountered Errors",
                            html: errors,
                            icon: "error",
                        }).then(() => {
                            this.show = true;
                        });
                    } else {
                        Swal.fire({
                            title: "Whoops!",
                            text: "There was an unexpected error. If this continues, please contact customer service.",
                            icon: "error",
                        }).then(() => {
                            this.show = true;
                        });
                    }
                })
                .finally(() => {
                    this.processing = false;
                });
        },
    },

    computed: {
        showQuestion2() {
            if (this.form.fillEmail === "1") {
                return true;
            }
            return false;
        },
        showQuestion3() {
            if (this.showQuestion2 && this.form.canReceiveEmail === "1") {
                return true;
            }
            return false;
        },
        btnSaveDisabled() {
            if (!this.form.code || !this.form.student_first_name || !this.form.student_last_name) {
                return true;
            }
            if (
                this.form.fillEmail === "0" ||
                (this.showQuestion2 && this.form.canReceiveEmail === "0") ||
                (this.showQuestion3 && this.form.useMicrosoftOrGoogle)
            ) {
                return false;
            }
            return true;
        },
        messageQ1() {
            if (this.form.fillEmail === "0") {
                return (
                    "As there are no email address associated with the code you are adding, this student will " +
                    "need to use the CourseWave code to log in and access the tests. Please distribute the " +
                    "CourseWave code you are adding to your student."
                );
            }
            return "";
        },
        messageQ2() {
            if (this.showQuestion2 && this.form.canReceiveEmail === "0") {
                return (
                    "Since your student is not able to receive email, we will not send an email " +
                    "notification about new resource being added or score reports. Please inform your student " +
                    "that new resource have been added to the account."
                );
            }
            if (this.showQuestion2 && this.form.canReceiveEmail === "1") {
                return (
                    "Since your student is able to receive email, we will send an email notification that " +
                    "a new resource has been added to the account. We will also send score reports when " +
                    "submissions are graded."
                );
            }
            return "";
        },
        messageQ3() {
            if (this.showQuestion3 && this.form.useMicrosoftOrGoogle === "0") {
                return (
                    "Your student will receive an email requesting that they setup a password for the " +
                    "CourseWave account. The student will need to use the email address and password to log in."
                );
            }
            if (this.showQuestion3 && this.form.useMicrosoftOrGoogle === "1") {
                return (
                    'Your student should use the "Sign in with Google" or "Sign in with Microsoft" buttons ' +
                    "to sign in to CourseWave. The student will not need to manage a password to access CourseWave."
                );
            }
            return "";
        },
    },
};
</script>

<template>
    <div class="">
        <BtnPrimary @click="show = true">
            Add Code

            <template #icon>
                <PlusSign />
            </template>
        </BtnPrimary>

        <ModalWindow
            v-model:show="show"
            header-classes="modal-header p-5 text-center"
            class="lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Add Code</h5>
            </template>

            <div class="mb-2 font-light italic text-gray-700">Fields with asterisk (*) are required</div>
            <!-- Resource code info -->
            <div class="lg:flex">
                <div class="flex-1">
                    <CText
                        v-model="form.code"
                        label="* Code:"
                        placeholder="Enter Code..."
                        required
                    />
                </div>

                <div class="mt-2 flex-1 lg:ml-2 lg:mt-0">
                    <CText
                        v-model="form.class_name"
                        label="Class:"
                        placeholder="Enter Class..."
                    />
                </div>

                <div class="mt-2 flex-1 lg:ml-2 lg:mt-0">
                    <CText
                        v-model="form.student_id"
                        label="Student ID:"
                        placeholder="Enter Student ID..."
                    />
                </div>
            </div>

            <div class="lg:mt-4 lg:flex">
                <div class="mt-2 flex-1 lg:mt-0">
                    <CText
                        v-model="form.student_first_name"
                        label="* Student First Name:"
                        placeholder="Enter Student First Name..."
                        required
                    />
                </div>

                <div class="mt-2 flex-1 lg:ml-2 lg:mt-0">
                    <CText
                        v-model="form.student_last_name"
                        label="* Student Last Name:"
                        placeholder="Enter Student Last Name..."
                        required
                    />
                </div>

                <div class="mt-2 flex-1 lg:ml-2 lg:mt-0">
                    <CText
                        v-model="form.student_email"
                        label="Student Email:"
                        placeholder="Enter Student Email..."
                        @input="updateFillEmail"
                    />
                </div>
            </div>

            <!-- Ask questions -->
            <div class="mt-4">
                * Did you enter a student email address?
                <div class="pl-2 font-normal">
                    <input
                        type="radio"
                        v-model="form.fillEmail"
                        value="1"
                    />
                    Yes
                    <input
                        type="radio"
                        v-model="form.fillEmail"
                        value="0"
                        class="ml-8"
                    />
                    No
                </div>
            </div>

            <Transition name="fade">
                <div
                    v-show="messageQ1"
                    v-html="messageQ1"
                    class="mt-4 text-center font-normal italic"
                ></div>
            </Transition>

            <Transition name="fade">
                <div
                    v-show="showQuestion2"
                    class="mt-4"
                >
                    * Is this student able to receive email?
                    <div class="pl-2 font-normal">
                        <input
                            type="radio"
                            v-model="form.canReceiveEmail"
                            value="1"
                        />
                        Yes
                        <input
                            type="radio"
                            v-model="form.canReceiveEmail"
                            value="0"
                            class="ml-8"
                        />
                        No
                    </div>
                </div>
            </Transition>

            <Transition name="fade">
                <div
                    v-show="messageQ2"
                    v-html="messageQ2"
                    class="mt-4 text-center font-normal italic"
                ></div>
            </Transition>

            <Transition name="fade">
                <div
                    v-show="showQuestion3"
                    class="mt-4"
                >
                    * Does this student use Microsoft or Google account for school?
                    <div class="pl-2 font-normal">
                        <input
                            type="radio"
                            v-model="form.useMicrosoftOrGoogle"
                            value="1"
                        />
                        Yes
                        <input
                            type="radio"
                            v-model="form.useMicrosoftOrGoogle"
                            value="0"
                            class="ml-8"
                        />
                        No
                    </div>
                </div>
            </Transition>

            <Transition name="fade">
                <div
                    v-show="messageQ3"
                    v-html="messageQ3"
                    class="mt-4 text-center font-normal italic"
                ></div>
            </Transition>

            <template #footer>
                <BtnSecondary
                    class="mr-2"
                    @click="show = false"
                >
                    Cancel
                </BtnSecondary>

                <BtnPrimary
                    padding="px-5 py-2"
                    @click="addCode"
                    class="w-auto"
                    :disabled="btnSaveDisabled || processing"
                >
                    <span
                        class="row no-gutters loading px-3 py-2"
                        v-if="processing"
                    >
                        <FontAwesomeIcon
                            icon="sync"
                            spin
                        />
                        Processing
                    </span>

                    <span v-else>Save</span>
                </BtnPrimary>
            </template>
        </ModalWindow>
    </div>
</template>

<style scoped></style>
