<script lang="ts" setup>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import BtnSecondary from "@/components/Global/BtnSecondary.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import FileImport from "@/components/Icons/FileImport.vue";
import axios from "axios"; // Data
import Swal from "sweetalert2";
import { computed, ref } from "vue";

// Data
const processing = ref(false);
const show = ref(false);
const form = ref({
    file: null,
    fillEmail: "",
    canReceiveEmail: "",
    useMicrosoftOrGoogle: "",
});
const file = ref(null);

const responseMsgHeader = ref("");
const responseMsg = ref("");

// Methods
const clearData = () => {
    // Clear form data
    document.getElementById("importCodeFile").value = null;
    form.value.file = null;
    form.value.fillEmail = "";
    form.value.canReceiveEmail = "";
    form.value.useMicrosoftOrGoogle = "";
};
const clearMsg = () => {
    responseMsgHeader.value = "";
    responseMsg.value = "";
};

const handleFileUpload = () => {
    form.value.file = file.value.files[0];
};
const importCodes = (event, proceed = false) => {
    const formData = new FormData();

    formData.append("file", form.value.file);
    formData.append("fillEmail", form.value.fillEmail);
    formData.append("canReceiveEmail", form.value.canReceiveEmail);
    formData.append("useMicrosoftOrGoogle", form.value.useMicrosoftOrGoogle);

    if (proceed) {
        formData.append("proceed", proceed);
    }

    processing.value = true;
    axios
        .post("/resources/import-codes", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            responseMsg.value = "We will email you once the resource codes have been imported. Thank you.";
            clearData();
        })
        .catch(({ response }) => {
            const status = response && response?.status ? response.status : undefined;
            const data = response && response?.data ? response.data : undefined;

            if (status === undefined) {
                responseMsgHeader.value = "Whoops!";
                responseMsg.value =
                    "No response from the server. Please try again later. If this continues, please contact customer service.";
            } else if (status === 409) {
                // If the import file doesn't have student name, it will ask if the user want to proceed.
                Swal.fire({
                    title: "Are you sure?",
                    html: data.message,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Proceed",
                }).then((result) => {
                    if (result.value) {
                        importCodes(null, true);
                    }
                });
            } else if (status === 422) {
                responseMsgHeader.value = "Encountered Errors";
                responseMsg.value = data?.message;
            } // Cloudflare will throw a 524 (timeout) error if the request takes longer than 100 seconds without server response
            else if (status === 524) {
                responseMsgHeader.value = "Whoops!";
                responseMsg.value = "Code import takes longer than expected. Please try again later.";
            } else {
                let msg = "There was an unexpected error. If this continues, please contact customer service.";
                if (data?.message) {
                    msg = data.message;
                }
                responseMsgHeader.value = "Whoops!";
                responseMsg.value = msg;
            }
        })
        .finally(() => {
            processing.value = false;
        });
};

// Computed
const showQuestion2 = computed(() => {
    if (form.value.fillEmail === "1") {
        return true;
    }
    return false;
});
const showQuestion3 = computed(() => {
    if (showQuestion2.value && form.value.canReceiveEmail === "1") {
        return true;
    }
    return false;
});
const btnImportDisabled = computed(() => {
    if (!form.value.file) {
        return true;
    }
    if (
        form.value.fillEmail === "0" ||
        (showQuestion2.value && form.value.canReceiveEmail === "0") ||
        (showQuestion3.value && form.value.useMicrosoftOrGoogle)
    ) {
        return false;
    }
    return true;
});
const messageQ1 = computed(() => {
    if (form.value.fillEmail === "0") {
        return (
            "As there are no email addresses associated with the codes you are importing, students will " +
            "need to use their CourseWave code to log in and access their tests. Please distribute the " +
            "CourseWave codes you are importing to your students."
        );
    }
    return "";
});
const messageQ2 = computed(() => {
    if (showQuestion2.value && form.value.canReceiveEmail === "0") {
        return (
            "Since your students are not able to receive email, we will not send them an email " +
            "notification about new resources being added or score reports. Please inform your students " +
            "that new resources have been added to their accounts."
        );
    }
    if (showQuestion2.value && form.value.canReceiveEmail === "1") {
        return (
            "Since your students are able to receive email, we will send them an email notification that " +
            "a new resource has been added to their account. We will also send them score reports when " +
            "submissions are graded."
        );
    }
    return "";
});
const messageQ3 = computed(() => {
    if (showQuestion3.value && form.value.useMicrosoftOrGoogle === "0") {
        return (
            "Your students will receive an email requesting that they setup a password for their " +
            "CourseWave account. They will need to use their email address and password to log in."
        );
    }
    if (showQuestion3.value && form.value.useMicrosoftOrGoogle === "1") {
        return (
            "Your students should use the button on the login page associated with their login provider to " +
            "sign in to CourseWave. They will not need to manage a password to access CourseWave."
        );
    }
    return "";
});
</script>

<template>
    <div class="text-left">
        <BtnPrimary
            class="whitespace-nowrap text-base"
            @click="show = true"
        >
            Import Codes
            <template #icon>
                <FileImport />
            </template>
        </BtnPrimary>

        <ModalWindow
            v-model:show="show"
            aria-modal="true"
            header-classes="modal-header p-5 text-center"
            class="text-base font-normal"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Import Codes</h5>
            </template>

            <div v-if="responseMsg">
                <h6
                    v-if="responseMsgHeader"
                    class="text-lg"
                >
                    {{ responseMsgHeader }}
                </h6>
                {{ responseMsg }}
            </div>

            <div v-else>
                <input
                    type="file"
                    ref="file"
                    id="importCodeFile"
                    @change="handleFileUpload()"
                />

                <!-- Ask questions-->
                <div class="mt-4">
                    Did you fill in email addresses with your code import?
                    <div class="pl-2 font-normal">
                        <label>
                            <input
                                type="radio"
                                v-model="form.fillEmail"
                                value="1"
                            />
                            Yes
                        </label>

                        <label>
                            <input
                                type="radio"
                                v-model="form.fillEmail"
                                value="0"
                                class="ml-8"
                            />
                            No
                        </label>
                    </div>
                </div>

                <Transition name="fade">
                    <div
                        v-show="messageQ1"
                        v-html="messageQ1"
                        class="mt-4 w-96 text-center font-normal italic"
                    ></div>
                </Transition>

                <Transition name="fade">
                    <div
                        v-show="showQuestion2"
                        class="mt-4"
                    >
                        Are these students able to receive email?
                        <div class="pl-2 font-normal">
                            <label>
                                <input
                                    type="radio"
                                    v-model="form.canReceiveEmail"
                                    value="1"
                                />
                                Yes
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    v-model="form.canReceiveEmail"
                                    value="0"
                                    class="ml-8"
                                />
                                No
                            </label>
                        </div>
                    </div>
                </Transition>

                <Transition name="fade">
                    <div
                        v-show="messageQ2"
                        v-html="messageQ2"
                        class="ml-8 mt-4 w-96 font-normal italic"
                    ></div>
                </Transition>

                <Transition name="fade">
                    <div
                        v-show="showQuestion3"
                        class="mt-4"
                    >
                        Will your students use SSO for logging in to CourseWave, such as
                        <br />
                        Microsoft, Google, Classlink, Clever, or other login service providers?
                        <div class="pl-2 font-normal">
                            <label>
                                <input
                                    type="radio"
                                    v-model="form.useMicrosoftOrGoogle"
                                    value="1"
                                />
                                Yes
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    v-model="form.useMicrosoftOrGoogle"
                                    value="0"
                                    class="ml-8"
                                />
                                No
                            </label>
                        </div>
                    </div>
                </Transition>

                <Transition name="fade">
                    <div
                        v-show="messageQ3"
                        v-html="messageQ3"
                        class="ml-8 mt-4 w-96 font-normal italic"
                    ></div>
                </Transition>
            </div>

            <template #footer>
                <div v-if="responseMsg">
                    <BtnPrimary
                        @click="
                            show = false;
                            clearMsg();
                        "
                    >
                        OK
                    </BtnPrimary>
                </div>

                <div v-else>
                    <BtnSecondary
                        class="mr-2"
                        @click="show = false"
                    >
                        Cancel
                    </BtnSecondary>

                    <BtnPrimary
                        @click="importCodes"
                        :disabled="btnImportDisabled"
                    >
                        <span
                            class="row no-gutters loading px-3 py-2"
                            v-if="processing"
                        >
                            <FontAwesomeIcon
                                icon="sync"
                                spin
                            />
                            Processing
                        </span>

                        <span v-else>Import</span>
                    </BtnPrimary>
                </div>
            </template>
        </ModalWindow>
    </div>
</template>
